import React, { useCallback, useState, useEffect } from 'react';
import { Card, List, Avatar, Input } from 'antd';

import styles from './ProductROI.less';

import DetailModal from './DetailModal';
import CardHeader from './CardHeader';
import QuestionTip from 'components/QuestionTip';
import locale from '../locale';

const data = [
  {
    title:
      '1M/2M/3M/4M Baby Bumper Bed Braid Knot Pillow Cushion Bumper for Infant Bebe Crib Protector Cot Bumper Room Decor'
  },
  {
    title:
      '1M/2M/3M/4M Baby Bumper Bed Braid Knot Pillow Cushion Bumper for Infant Bebe Crib Protector Cot Bumper Room Decor'
  },
  {
    title:
      '1M/2M/3M/4M Baby Bumper Bed Braid Knot Pillow Cushion Bumper for Infant Bebe Crib Protector Cot Bumper Room Decor'
  },
  {
    title:
      '1M/2M/3M/4M Baby Bumper Bed Braid Knot Pillow Cushion Bumper for Infant Bebe Crib Protector Cot Bumper Room Decor'
  }
];

const productData = {
  store_id: 'brbos7uelgm000ejf4f0',
  shopify_product_id: 4695204659257,
  product_type: 'Home & Garden',
  ae_product_id: 4001204738025,
  ae_product_title: 'Household Wireless Charging-Scraping Instrument',
  ae_url: 'https://www.aliexpress.com/item/4001204738025.html',
  ae_img: 'https://ae01.alicdn.com/kf/H66b848c68c474f88b5ebd3f5ee5bd4ac0.jpg',
  ae_price: '4.99'
};

function ProductTitle() {
  return (
    <>
      Product ROI
      <QuestionTip content={locale.report.product_roi} />
    </>
  );
}

function ProductROI() {
  const [visible, setVisible] = useState(false);
  const handleSearch = useCallback(value => {
    console.log('handleSearch', value);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  function handleCheck() {
    setVisible(true);
  }

  return (
    <div className={styles.prodRoi}>
      <CardHeader title={<ProductTitle />} />
      <Card>
        <div className={styles.header}>
          <h4>Top 10 products</h4>
          <span>
            <Input.Search
              placeholder="input search text"
              onSearch={handleSearch}
              style={{ width: 350 }}
            />
          </span>
        </div>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item
              actions={[
                <a
                  role="button"
                  tab-index="0"
                  onClick={handleCheck}
                >
                  Check detailed report
                </a>
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src="https://ae01.alicdn.com/kf/H6b8c1200b30f45dabc3f6cb91d3eca14D.jpg"
                    shape="square"
                    size="large"
                  />
                }
                title={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.aliexpress.com/item/4001345072393.html"
                  >
                    {item.title}
                  </a>
                }
                description="Team"
              />
            </List.Item>
          )}
        />
      </Card>
      <DetailModal
        visible={visible}
        data={productData}
        onCancel={handleClose}
      />
    </div>
  );
}

export default ProductROI;
