import React from 'react';
import styles from './Banner.less';
import banner from 'assets/supply/newPartners.png';

const Banner = () => {
  return (
    <a
      href="https://growave.io/trackRedirectInstall?utm_source=partner&utm_medium=in-app&utm_campaign=dsers2"
      target="_blank"
      rel="noopener noreferrer"
      data-category="report-banner"
      data-action="open Audiencer"
      className="open_audiencer"
    >
      <div className={styles.banner}>
        <div className={styles.box}>
          <img src={banner} alt="" />
          <div>Increase repeat purchases with our Loyalty and Rewards Program.</div>
          <span>Learn more</span>
        </div>
      </div>
    </a>
  );
};

export default Banner;
