import React from 'react';
import { Icon, Tooltip } from 'antd';

import styles from './index.less';

function QuestionTip({ content, style, ...props }) {
  return (
    <span className={styles.questionTip}>
      <Tooltip title={content} {...props}>
        <Icon type="question-circle" style={style} />
      </Tooltip>
    </span>
  );
}

export default QuestionTip;
