const chartColors = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};

export const lineChartData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Product cost',
      borderColor: chartColors.blue,
      backgroundColor: chartColors.blue,
      fill: false,
      borderWidth: 2,
      pointRadius: 2,
      data: [2, 61, -31, -92, 95, 29, 70],
      yAxisID: 'y-axis-2'
    },
    {
      label: 'Sales',
      borderColor: chartColors.red,
      backgroundColor: chartColors.red,
      fill: false,
      borderWidth: 2,
      pointRadius: 2,
      data: [21, 21, -92, 48, 67, -40, -63],
      yAxisID: 'y-axis-1'
    },
    {
      label: 'Ad cost',
      borderColor: chartColors.yellow,
      backgroundColor: chartColors.yellow,
      fill: false,
      borderWidth: 2,
      pointRadius: 2,
      data: [65, 71, -56, -53, -43, 43, -21],
      yAxisID: 'y-axis-1'
    },
    {
      label: 'ROI',
      borderColor: chartColors.green,
      backgroundColor: chartColors.green,
      fill: false,
      borderWidth: 2,
      pointRadius: 2,
      data: [87, 13, 44, -56, -61, 24, 11],
      yAxisID: 'y-axis-1'
    }
  ]
};
