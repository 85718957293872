import React, { useMemo } from 'react';

import styles from './Rate.less';

function Rate({ val }) {
  const result = useMemo(() => {
    if (!val) {
      return 0;
    }

    // if (val.indexOf('0.00') > -1) {
    //   return 0;
    // }

    if (val.indexOf('-') > -1) {
      return -1;
    }

    return 1;
  }, [val]);

  return (
    <span className={styles.rate}>
      {result === 1 ? <span className={styles.up} /> : null}
      {result === -1 ? <span className={styles.down} /> : null}
      {result === 0 ? (
        <span className={styles.noData}>--</span>
      ) : (
        <span className={styles.val}>{val}</span>
      )}
    </span>
  );
}

export default Rate;
