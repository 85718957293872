import currencySymbolMap from 'common/currencySymbolMap';

/**
 * 获取时区偏移量，eg. '(GMT+08:00) Beijing' to '+08:00'
 *
 * @param {*} timeZoneStr
 * @returns string
 */
export const getTimeZoneFix = timeZoneStr => {
  let TZ;
  if (!timeZoneStr) {
    return TZ;
  }

  const zone = /\(GMT(.+?)\).*/.exec(timeZoneStr);
  if (zone && zone[1]) {
    [, TZ] = zone;
  }

  return TZ;
};

export const calculatePriceByExchange = (value, rate) => (value / 100) * rate;

export const formatPrice = (money, currency, exchange = true) => {
  if (isNaN(money) || !currency) {
    return '--';
  }

  let price = 0;

  if (exchange) {
    price = calculatePriceByExchange(money, currency.rates_value);
  } else {
    price = money / 100;
  }

  if (isNaN(price)) {
    return '--';
  }

  const value = price.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });

  const symbol = currencySymbolMap[currency.currency_code];

  return `${symbol}${value}`;
};

export const formatChartData = ({ data = [], label = [], title }) => {
  const config = {
    labels: label,
    datasets: [
      {
        label: title,
        fill: 'start',
        borderColor: '#8BC34A',
        borderWidth: 3,
        pointRadius: 2,
        pointBackgroundColor: '#8BC34A',
        lineTension: 0.4,
        data
      }
    ]
  };

  return canvas => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 100);

    gradient.addColorStop(0, 'rgba(68,135,237,0.7)');
    gradient.addColorStop(1, 'rgba(68,135,237,0)');
    config.datasets[0].backgroundColor = gradient;

    return config;
  };
};
