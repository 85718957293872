import React from 'react';

import styles from './CardHeader.less';

function CardHeader({ title, children }) {
  return (
    <div className={styles.header}>
      <span className={styles.title}>{title}</span>
      <div>{children}</div>
    </div>
  );
}

CardHeader.defaultProps = {
  children: null
};

export default CardHeader;
