import React from 'react';
import { Link } from 'dva/router';
import intl from 'react-intl-universal';

import img from '../../../assets/img/icon-report-unbind.png';
import styles from './NotAccess.less';

import { getLanguage } from 'utils/utils';

function NotAccess({ type, store }) {
  let clickHref = 'https://help.dsers.com/account-report-for-shopify/';

  if (getLanguage() === 'fr-FR') {
    clickHref =
      'https://help.dsers.com/account-report-for-shopify/';
  }

  if (getLanguage() === 'pt-PT') {
    clickHref =
      'https://help.dsers.com/account-report-for-shopify/';
  }
  return (
    <div className={styles.notAccess}>
      <img src={img} alt="unbind-store-img" />
      {type === 1 ? (
        <div className={styles.intro}>
          {intl.get('rapport.link')}
          <Link to="/bind/link-store">{intl.get('rapport.more')}</Link>
        </div>
      ) : null}
      {type === 2 ? (
        <div className={styles.intro}>
          Please <Link to="/settings?id=3">set up the currency</Link> and <Link to="/settings?id=1">time zone</Link> for the
          store <span>{store && store.shop_name || '-'}</span> to activate the report feature.
          <br />
          Check&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={clickHref}
          >
            how to use the Report
          </a>
          &nbsp; feature.
        </div>
      ) : null}
    </div>
  );
}

export default NotAccess;
