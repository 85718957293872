export const saleChartOption = {
  responsive: true,
  legend: false,
  layout: {
    padding: {
      left: 0,
      right: 8,
      top: 4,
      bottom: 0
    }
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: false // this will remove only the label
        },
        gridLines: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          display: false // this will remove only the label,
        },
        gridLines: {
          drawBorder: false
        }
      }
    ]
  }
};

export const detailChartOption = {
  responsive: true,
  // hoverMode: 'index',
  tooltips: {
    mode: 'index',
    intersect: false
  },
  hover: {
    mode: 'nearest',
    intersect: true
  },
  stacked: false,
  title: {
    display: false,
    text: 'Chart.js Line Chart - Multi Axis'
  },
  scales: {
    yAxes: [
      {
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        position: 'left',
        id: 'y-axis-1'
      },
      {
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        position: 'right',
        id: 'y-axis-2',
        // grid line settings
        gridLines: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        }
      }
    ]
  }
};
