import React, { useMemo, useEffect, useState } from 'react';
import { connect } from 'dva';
import intl from 'react-intl-universal';

import TotalSale from './component/TotalSale';
import NotAccess from './component/NotAccess';
import ProductROI from './component/ProductROI';
import Banner from './component/Banner';
import success from './success.gif';

import { CommonDataCollect } from 'utils/utils';

import styles from './index.less';
import { Alert } from 'antd';
import { Get, Post } from 'utils/request';

function Report({ currency, userInfo, timeZone, storeId, oberloInfo }) {

  const [storeInfo,setStoreInfo] = useState(false)
  const [customizeData,setCustomizeData] = useState()

  useEffect(() => {
    CommonDataCollect({
      event_type: 'subscription_analysis',
      action: 'browse_report_tab',
    })
  },[])


  const memoStore = useMemo(() => {
    if (!(userInfo && userInfo.stores && userInfo.stores.length > 0)) {
      return {
        type: 1
      };
    }

    const target = userInfo.stores.find(i => i.id === storeId);

    if (!(target && target.ali_currency)) {
      setStoreInfo(false)
      return {
        type: 2,
        store: target
      };
    }

    if (!(currency && Object.keys(currency).length > 0) || !timeZone) {
      setStoreInfo(false)
      return {
        type: 2,
        store: target
      };
    }

    return {
      type: 0
    };
  }, [currency, userInfo, timeZone, storeId]);

  // 24小时提示 每次更改时间或者店铺都会触发
  const getTotalSaleData = async (value) => {
    const data = await Get('/users/session',{})
    const customizeData = data.data && JSON.parse(data.data.reportData)
    const date = new Date().valueOf()
    if(value.ae_cost == 0 && value.ae_order_count == 0 && value.sp_order_count == 0 && value.sp_price == 0){
      if(customizeData && Object.keys(customizeData)?.includes(storeId) && (date - customizeData[storeId] < 86400000)){
        setStoreInfo(true)
      }else{
        setStoreInfo(false)
      }
      if(customizeData && !Object.keys(customizeData)?.includes(storeId)){
        let obj = {
          ...customizeData,
          [storeId] : date
        }
        await Post('/users/session',{
          data:{
            reportData: JSON.stringify(obj)
          }
        })
        setStoreInfo(true)
      }
      if(!customizeData){
        let obj = {
          [storeId] : date
        }
        await Post('/users/session',{
          data:{
            reportData: JSON.stringify(obj)
          }
        })
        setStoreInfo(true)
      }
    }else{
      setStoreInfo(false)
    }
  }

  return (
    <div className={styles.reportPage}>
      {storeInfo ?       
      <Alert
        style={{ marginBottom: 24,color:'#F8AE44' }}
        message={intl.getHTML('public.add_report_user_tip')}
        type="warning"
        className={styles.alert}
        showIcon
      /> : null }
      <h1>{intl.get('rapport.report')}</h1>
      {memoStore.type === 0 ? (
        <>
          <TotalSale getTotalSaleData={getTotalSaleData}/>
          {process.env.NODE_ENV === 'development' ? <ProductROI /> : null}
        </>
      ) : (
        <NotAccess type={memoStore.type} store={memoStore.store} />
      )}
      <div className={styles.footerBanner}>
        <Banner />
      </div>
    </div>
  );
}

export default connect(({ global, login, auth }) => ({
  currency: global.storeCurrency,
  timeZone: global.settingTimeZone,
  userInfo: login.userInfo,
  storeId: global.storeId,
  oberloInfo:auth.OstoreInfo
}))(Report);
