import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DatePicker, Card, Spin } from 'antd';
import { Line } from 'react-chartjs-2';
import { stringify } from 'qs';
import intl from 'react-intl-universal';
import { connect } from 'dva';
import moment from 'moment-timezone';

import { Get } from 'utils/request';
import { saleChartOption } from './chartOption';
import CardHeader from './CardHeader';
import {
  formatPrice,
  formatChartData,
  calculatePriceByExchange
} from '../utils';
import QuestionTip from 'components/QuestionTip';
import Rate from './Rate';
import locale from '../locale';

import styles from './TotalSale.less';

const startDate = new Date(2020, 8, 17); // 开始统计的时间

function TotalSale({ storeId, timezoneRegion, currency, getTotalSaleData }) {
  console.log('--------- TotalSale ---------');
  const [saleData, setSaleData] = useState(null);
  const [saleLoading, setSaleLoading] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    async function fetchProductData() {
      setSaleLoading(true);

      const params = {
        store_id: storeId
        // store_id: 'bsua9lqlg1n000etutk0'
      };

      if (!date || date.length === 0) {
        const current = moment()
          .tz(timezoneRegion)
          .subtract(1, 'days')
          .endOf('day');

        const prev = moment()
          .tz(timezoneRegion)
          .subtract(31, 'days')
          .startOf('day');

        const start = moment(startDate).tz(timezoneRegion);

        Object.assign(params, {
          start_time: start < prev ? prev.format() : start.format(),
          end_time: current.format()
        });
      } else {
        const start = date[0]
          .clone()
          .tz(timezoneRegion)
          .startOf('day')
          .format();

        const end = date[1]
          .clone()
          .tz(timezoneRegion)
          .endOf('day')
          .format();

        Object.assign(params, {
          start_time: start,
          end_time: end
        });
      }

      // Object.assign(params, {
      //   start_time: '2020-08-15T00:00:00+08:00',
      //   end_time: '2020-08-20T00:00:00+08:00'
      // });

      try {
        const res = await Get(`/report/order?${stringify(params)}`);

        if (res && res.code === 2000) {
          if (res.data) {
            setSaleData(res.data);
          }
        //调用父组件方法 通知父组件 传递report数据
        getTotalSaleData(res.data)
        }
      } catch (error) {
        console.log('fetch error:', error);
      }
      setSaleLoading(false);
    }

    if (storeId && timezoneRegion) {
      fetchProductData();
    }
  }, [storeId, date, timezoneRegion]);

  const memorizeData = useMemo(() => {
    if (!saleData || !currency) {
      return {};
    }

    /**
     * status:
     * 0 不计算汇率
     * 1 计算汇率
     *
     * @param {*} rawData
     * @param {*} status
     * @returns
     */
    const format = (rawData, status) => {
      const labels = [];
      const value = [];

      // eslint-disable-next-line
      for (const item of rawData) {
        labels.push(item.date);
        if (status === 1) {
          const tmp =
            calculatePriceByExchange(item.count, currency.rates_value) || 0;
          value.push(tmp.toFixed(2));
        } else if (status === 0) {
          value.push((item.count / 100).toFixed(2));
        } else {
          value.push(item.count);
        }
      }

      return {
        labels,
        value
      };
    };

    const sfOrder = format(saleData.sp_order_count_list);
    const sfSale = format(saleData.sp_price_list, 0);
    const aeOrder = format(saleData.ae_order_count_list);
    const aeCost = format(saleData.ae_cost_list, 0);

    return {
      ...saleData,
      sfOrder: formatChartData({
        data: sfOrder.value,
        label: sfOrder.labels,
        title: 'Orders'
      }),
      sfSale: formatChartData({
        data: sfSale.value,
        label: sfSale.labels,
        title: 'Sales'
      }),
      aeOrder: formatChartData({
        data: aeOrder.value,
        label: aeOrder.labels,
        title: 'Orders'
      }),
      aeCost: formatChartData({
        data: aeCost.value,
        label: aeCost.labels,
        title: 'Cost'
      }),
      sp_value: formatPrice(saleData.sp_price, currency, false),
      ae_value: formatPrice(saleData.ae_cost, currency, false)
    };
  }, [saleData, currency]);

  function handleDateChange(value) {
    setDate(value);
  }

  // function handleTimeZoneChange(value) {
  //   setTimeZone(value);
  // }

  const disabledDate = useCallback(
    current => {
      const time = moment()
        .tz(timezoneRegion)
        ?.add(-1, 'days')
        .endOf('day');

      const tmp = current.clone().tz(timezoneRegion);

      return (tmp && tmp >= time) || (tmp && tmp.toDate() < startDate);
    },
    [timezoneRegion]
  );

  const defaultTime = useMemo(() => {
    const start = moment(startDate).tz(timezoneRegion);
    const monthAgo = moment()
      .tz(timezoneRegion)
      ?.add(-31, 'days');

    const tmp = start < monthAgo ? monthAgo : start;

    return [
      tmp,
      moment()
        .tz(timezoneRegion)
        ?.add(-1, 'days')
    ];
  }, [timezoneRegion]);

  return (
    <>
      <CardHeader title={intl.get('guidance.total_sales')}>
        <DatePicker.RangePicker
          onChange={handleDateChange}
          defaultValue={defaultTime}
          getCalendarContainer={trigger => trigger.parentNode}
          // ranges={{
          //   Yesterday: [moment().add(-1, 'days'), moment().add(-1, 'days')],
          //   'Last Month': [
          //     moment().add(-31, 'days'),
          //     moment().add(-1, 'days')
          //   ]
          // }}
          suffixIcon={
            <span>
              <i
                style={{ fontSize: 18, marginTop: '-2px' }}
                className="material-icons"
              >
                calendar_today
              </i>
            </span>
          }
          disabledDate={disabledDate}
          allowClear={false}
        />
      </CardHeader>
      {/* <Card> */}
      <Spin spinning={saleLoading}>
        <div className={styles.saleBox}>
          <div className={styles.con}>
            <div>
              <h4>
                Shopify Orders
                <QuestionTip content={locale.report.sp_orders} />
              </h4>
              <div className={styles.trend}>
                <span className={styles.number}>
                  {isNaN(memorizeData.sp_order_count)
                    ? '--'
                    : memorizeData.sp_order_count}
                </span>
                <Rate val={memorizeData.sp_order_increase} />
              </div>
            </div>
            {memorizeData.sfOrder ? (
              <Line
                data={memorizeData.sfOrder}
                options={saleChartOption}
                height={140}
              />
            ) : (
              <div className={styles.noData}>No data</div>
            )}
          </div>
          <div className={styles.con}>
            <div>
              <h4>
              {intl.get('guidance.shopify_sales')}
                <QuestionTip content={locale.report.sp_sales} />
              </h4>
              <div className={styles.trend}>
                <span className={styles.number}>{memorizeData.sp_value}</span>
                <Rate val={memorizeData.sp_price_increase} />
              </div>
            </div>
            {memorizeData.sfSale ? (
              <Line
                data={memorizeData.sfSale}
                options={saleChartOption}
                height={140}
              />
            ) : (
              <div className={styles.noData}>No data</div>
            )}
          </div>
          <div className={styles.con}>
            <div>
              <h4>
              {intl.get('guidance.ali_orders')}
                <QuestionTip content={locale.report.ali_orders} />
              </h4>
              <div className={styles.trend}>
                <span className={styles.number}>
                  {isNaN(memorizeData.ae_order_count)
                    ? '--'
                    : memorizeData.ae_order_count}
                </span>
                <Rate val={memorizeData.ae_order_increase} />
              </div>
            </div>
            {memorizeData.aeOrder ? (
              <Line
                data={memorizeData.aeOrder}
                options={saleChartOption}
                height={140}
              />
            ) : (
              <div className={styles.noData}>No data</div>
            )}
          </div>
          <div className={styles.con}>
            <div>
              <h4>
              {intl.get('guidance.supplier_cost')}
                <QuestionTip content={locale.report.supplier_cost} />
              </h4>
              <div className={styles.trend}>
                <span className={styles.number}>{memorizeData.ae_value}</span>
                <Rate val={memorizeData.ae_cost_increase} />
              </div>
            </div>
            {memorizeData.aeCost ? (
              <Line
                data={memorizeData.aeCost}
                options={saleChartOption}
                height={140}
              />
            ) : (
              <div className={styles.noData}>No data</div>
            )}
          </div>
        </div>
      </Spin>
      {/* </Card> */}
    </>
  );
}

export default connect(({ global }) => {
  const { storeId, storeCurrency, timezoneRegion } = global;
  let currency;
  if (storeId && storeCurrency) {
    currency = storeCurrency[storeId];
  }

  return {
    storeId,
    currency,
    timezoneRegion
  };
})(TotalSale);
// (React.memo(TotalSale)
