// 由于 report 分支代码滞后，暂时写在这里
export default {
  report: {
    sp_orders:
      'This data is about the total amount of Shopify orders in the given date range. You can change the date range according to your needs. It does not include Shopify testing orders and deleted orders. DSers only sync order status change for 7 days. During those 7 days, if an order is deleted of refunded, DSers will update the report. ',
    sp_sales:
      'This data is about the total amount of Shopify sales in the given date range. You can change the date range according to your needs. Shopify sales = Gross sales - Discounts - Returns + Taxes + Shipping cost. DSers only sync order status change for 7 days. During those 7 days, if an order is deleted of refunded, DSers will update the report. ',
    ali_orders:
      'This data is about the total amount of AliExpress orders that you processed via DSers in the given date range. You can change the date range according to your needs. Canceled orders are not included and DSers only sync order status change for 7 days. During those 7 days, if an order is deleted of refunded, DSers will update the report. ',
    supplier_cost:
      'This data is about the total amount you spent on AliExpress for the product cost in the given date range. You can change the date range according to your needs. Canceled orders are not included and DSers only sync order status change for 7 days. During those 7 days, if an order is deleted of refunded, DSers will update the report.',
    media_cost:
      'This data is about the total Facebook Ad spending in the last 30 days. You can change the date range according to your needs. Click here to check how to connect your Facebook Ad account.',
    other_cost:
      'The data is about the ROI of each product. We will calculate the daily ROI of a product according to the daily Shopify sales, estimated product cost and ad spending (if you connected to your Facebook Ad account), no matter if you purchased the product that day or not.',
    product_roi:
      'The data is about the ROI of each product. We will calculate the daily ROI of a product according to the daily Shopify sales, estimated product cost and ad spending (if you connected to your Facebook Ad account), no matter if you purchased the product that day or not. '
  }
};
