import React from 'react';
import { Modal, Select, DatePicker } from 'antd';
import { connect } from 'dva';
import { Line } from 'react-chartjs-2';

import styles from './DetailModal.less';
import { replaceUrlToHttps } from 'utils/utils';
import { detailChartOption } from './chartOption';
import { lineChartData } from './mockData';

const DetailModal = ({ visible, data, onCancel }) => {
  console.log('------- DetailModal -------', visible);
  function handleCancel() {
    onCancel();
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      width={920}
      maskClosable={false}
      keyboard={false}
      onCancel={handleCancel}
      bodyStyle={{ padding: '48px 48px' }}
      destroyOnClose
    >
      <div className={styles.top}>
        <div className={styles.prodInfo}>
          <img className={styles.img} src={replaceUrlToHttps(data.ae_img)} alt="" />
          <div className={styles.infoRight}>
            <a
              href={data.ae_url}
              target="_blank"
              rel="noopener noreferrer"
              title={data.ae_product_title}
            >
              {data.ae_product_title}
            </a>
          </div>
        </div>
        <div>
          <DatePicker.RangePicker allowClear={false} style={{ width: 260 }} />
          <Select defaultValue="lucy" style={{ width: 120, marginLeft: 12 }}>
            <Select.Option value="jack">Jack</Select.Option>
            <Select.Option value="Lucy">Lucy</Select.Option>
          </Select>
        </div>
      </div>
      <div className={styles.content}>
        <Line
          data={lineChartData}
          options={detailChartOption}
          // height={220}
        />
        {/* <div className={styles.chart}>
          <Line
            data={lineChartData}
            options={detailChartOption}
            // height={220}
          />
        </div>
        <div className={styles.sidebar}>sidebar</div> */}
      </div>
    </Modal>
  );
};

export default connect(({ login }) => ({
  userInfo: login.userInfo
}))(React.memo(DetailModal));
